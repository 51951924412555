<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-card max-width="800" class="mx-auto">
    <v-toolbar color="blue" dark>
      <v-toolbar-title v-if="showTypes"
        >Selezionare la tipologia di supporto/Please select support
        type</v-toolbar-title
      >
      <v-toolbar-title v-else-if="typeSelected != null">{{
        typeSelected.title
      }}</v-toolbar-title>
      <v-toolbar-title v-else>Titolo altenativo</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            <v-icon>mdi-help-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item href="/SupportIT.pdf" target="_blank">
            <v-list-item-icon>
              <v-icon>mdi-adobe-acrobat</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Italiano</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item href="/SupportEN.pdf" target="_blank">
            <v-list-item-icon>
              <v-icon>mdi-adobe-acrobat</v-icon>
            </v-list-item-icon>
            <v-list-item-title>English</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <div class="my-2" v-if="canGoBackToService()">
      <v-btn text small color="primary" @click="backToService()"
        ><v-icon left>mdi-reply</v-icon>Torna al servizio/Back to
        application</v-btn
      >
    </div>

    <request-types-list
      v-if="showTypes"
      @setType="setType"
    ></request-types-list>
    <request-form
      v-else-if="showFields"
      :typeSelected="typeSelected"
      @loadTypes="loadTypes"
    ></request-form>
    <v-snackbar v-model="snackbar.display">
      {{ snackbar.text }}
      <v-btn color="pink" text @click="snackbar.display = false"
        >Chiudi/Close</v-btn
      >
    </v-snackbar>
  </v-card>
</template>

<script>
import VueJwtDecode from "vue-jwt-decode";
import RequestTypesList from "../components/RequestTypesList";
import RequestForm from "../components/RequestForm";
export default {
  name: "Support",
  components: { RequestForm, RequestTypesList },
  methods: {
    backToService: function() {
      let decodedJwt = VueJwtDecode.decode(sessionStorage.getItem("jwt_token"));
      window.location.href = decodedJwt.referer;
    },
    canGoBackToService: function() {
      let decodedJwt = VueJwtDecode.decode(sessionStorage.getItem("jwt_token"));
      if (decodedJwt.referer) {
        return true;
      } else {
        return false;
      }
    },
    setType: function(type) {
      this.$data.typeSelected = type;
      this.showTypes = false;
      this.showFields = true;
    },
    loadTypes: function() {
      this.$data.typeSelected = null;
      this.showTypes = true;
      this.showFields = false;
    }
  },
  data: () => ({
    rules: [v => !!v || "Campo obbligatorio/Field mandatory"],
    idReq: null,
    emailRules: [
      v => !!v || "E-mail è obbligatoria/Email is mandatory",
      v => /.+@.+/.test(v) || "E-mail deve essere valida/Must be a valid email"
    ],
    items: [],
    loadingRequestTypes: false,
    loadingFields: false,
    typeSelected: null,
    attachments: null,
    showFields: false,
    showFeedback: false,
    loadingFeedback: false,
    snackbar: {
      display: false,
      text: ""
    },
    showTypes: true,
    requestModelFields: [],
    requestModelData: {},
    requestModelTypes: {}
  })
};
</script>

<style scoped></style>
